const isUserLoggedIn = () => {
  // Check localStorage
  const userID = localStorage.getItem("profile");

  // Check cookies for authToken
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const authToken = getCookie("authToken");

  // Return true only if both userID and authToken exist
  if (!userID && !authToken) {
    return false;
  }
  return true;
};

export default isUserLoggedIn;
