import React, { useState, useEffect, useMemo } from "react";
import Grid from "@mui/material/Grid";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
//import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import CourseTabItem from "../../tabs/clients/CourseTabItem";
import ScreenerTabItem from "../../tabs/clients/ScreenerTabItem";
import AppointmentTabItem from "../../tabs/clients/AppointmentTabItem";
import NoteTabItem from "../../tabs/clients/NoteTabItem";
import PrescriptionTabItem from "../../tabs/clients/PrescriptionTabItem";

import { getSessionNotes, getSessionNotesById } from "../../APIS/clientAPIS";
import { Chip, Divider, Pagination, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import moment from "moment";
import dayjs from "dayjs";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import TextField from "@mui/material/TextField";

//Loader spinner
import CircularProgress from "@mui/material/CircularProgress";

//Accordion

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useAuth } from "../../../../auth";
import jwtDecode from "jwt-decode";
import AddAppointmentModal from "../../modals/AddAppointmentModal";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import SessionNoteTabItem from "../../tabs/clients/SessionNoteTabItem";

import { getToken } from "../../APIS/clientAPIS";
import { ChatBubbleOutlineOutlined, ChatOutlined, TextSnippetOutlined } from "@mui/icons-material";
import { NoDataSvg } from "../../helpers/Svg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ClientDetailsView = ({
  clientName,
  onScreenerView,
  clientID,
  clientDetails,
}) => {
  // console.log("clientName", clientName)
  // console.log("clientID", clientID)
  // console.log("clientDetails", clientDetails)
  const [selectedTab, setSelectedTab] = useState(0);
  const [clientViewDetails, setClientViewDetails] = useState({});
  const [checkClientID, setCheckClientID] = useState(clientID);
  const [clientAppointmentsList, setClientAppointmentsList] = useState([]);
  const [clientNotes, setClientNotes] = useState([]);
  const [clientSessionNotes, setClientSessionNotes] = useState([]);
  const [clientPrescriptions, setClientPrescriptions] = useState([]);
  const [clientScreeners, setClientScreeners] = useState([]);
  const [mergedClientScreeners, setMergedClientScreeners] = useState([]);
  const [clientScreenersQNA, setClientScreenersQNA] = useState([]);
  const [clientCourses, setClientCourses] = useState([]);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [openSessionNotesModal, setOpenSessionNotesModal] = useState(false);
  const [note, setNote] = useState("");
  const [chatClientList, setChatClientList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [bearerToken, setBearerToken] = useState(localStorage.getItem("token"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userUploadedPrescriptions, setUserUploadedPrescriptions] = useState(
    []
  );
  const [isNoteAdded, setIsNoteAdded] = useState(null);

  const [sessionNoteView, setSessionNoteView] = useState({});

  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const { currentUser, userType, logout } = useAuth();
  const navigate = useNavigate();
  const searchBarParams = useSearchParams();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const { search } = useLocation();
  const tabIndexFromURL = new URLSearchParams(search).get("tabIndex");

  //console.log("queryParam", checkClientID)

  //if tabIndexFromURL is present
  useEffect(() => {
    setSelectedTab(+tabIndexFromURL);
  }, []);

  useEffect(() => {
    setCheckClientID(clientID);
    setClientViewDetails(clientDetails);
  }, [clientID]);

  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  //Check if CLient ID
  const getClientID = () => {
    if (
      (checkClientID === "" ||
        checkClientID === null ||
        checkClientID === undefined) &&
      window.location.href.split("?")[1].includes("id")
    ) {
      const queryParam = new URLSearchParams(search).get("id");
      setCheckClientID(queryParam);
    } else {
      setCheckClientID(clientID);
    }
  };

  useEffect(() => {
    getClientID();
  }, []);

  // Define the sorting function
  const sortByDate = (a, b) => {
    // Extract score_date or create_date from each item
    let dateA = a.score_date || a.create_date;
    let dateB = b.score_date || b.create_date;

    // Convert to date objects
    dateA = new Date(dateA);
    dateB = new Date(dateB);

    // Compare the dates
    return dateB - dateA; // This will sort in ascending order
  };

  //Fetch Client Screeners
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    ///ADDED API IN CLIENTAPIS
    ///IMPLEMENTATION PENDING

    const postClientScreenersData = async () => {
      try {
        const screenerBody = {
          expert_id: currentUser?.UserId,
          user_id: checkClientID,
          // user_id: currentUser?.UserId,
        };

        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/assignments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(screenerBody),
          }
        );
        const postResponse = await response.json();

        const { assignments } = await postResponse.data;
        const { worksheets } = await postResponse.data;

        setClientScreeners(assignments);
        setClientScreenersQNA(worksheets);

        // Modify worksheets to include score_date and type
        const modifiedWorksheets = worksheets.map((worksheet) => ({
          ...worksheet,
          score_date: worksheet.create_date,
          type: "QNA", // Copy create_date to score_date
        }));

        // Modify assignments to include score_date
        const modifiedAssignments = assignments.map((worksheet) => ({
          ...worksheet,
          type: "Options",
        }));

        // Merge the arrays
        const mergedClientScreeners = [
          ...modifiedAssignments,
          ...modifiedWorksheets,
        ];
        // Sort the merged array
        const sortedMergedClientScreeners =
          mergedClientScreeners.sort(sortByDate);

        setMergedClientScreeners(sortedMergedClientScreeners);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientScreenersData();
  }, [checkClientID]);

  //Fetch Client Appointments
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    const payload = {
      expert_id: currentUser?.UserId,
      user_id: Number(checkClientID),
      page: page
    };

    const postClientAppointmentsData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { appointments, total_pages } = await postResponse.data;

        setClientAppointmentsList(appointments);
        setTotalPage(total_pages)
        //setUserData(postResponse?.data);

        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientAppointmentsData();
    setLoading(false);
  }, [checkClientID, page]);

  //Fetch Client Notes
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    const payload = {
      jsonrpc: "2.0",
      params: {
        creator_id: +currentUser?.UserId,
        assign_id: +checkClientID,
      },
    };

    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/uwc_notes/list`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        // console.log("postResponse", postResponse);

        const token = await postResponse.data;
        const decodedToken = jwtDecode(token);

        setClientNotes(decodedToken?.notes);

        //console.log("data", decodedToken?.notes);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();
    setLoading(false);
  }, [checkClientID]);

  //Fetch Client Prescriptions
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    const postClientNotesData = async (retryCount = 0) => {
      const MAX_RETRY = 3; // Maximum number of retries
      try {
        const response = await fetch(
          `https://session-note.uwc.world/clinicalnotes/appointment-prescriptions?expert_id=${currentUser?.UserId}&user_id=${checkClientID}&page=1&count=1000`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              token:
                localStorage.getItem("Authorization")?.replace("Bearer ", "") ||
                "",
            },
          }
        );
        const postResponse = await response.json();

        // Maintain existing retry logic for 401 errors
        if (postResponse?.ResponseCode == 401 && retryCount < MAX_RETRY) {
          getToken(currentUser?.Emailid).then((res) => {
            setTimeout(() => postClientNotesData(retryCount + 1), 0);
          });
        }

        // Maintain existing success logic
        if (postResponse?.status === "success") {
          const prescriptionData = postResponse?.data;
          setClientPrescriptions(
            prescriptionData?.filter((item) => item.uploaded_by === "expert")
          );
          setUserUploadedPrescriptions(
            prescriptionData?.filter((item) => item.uploaded_by === "user")
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
 

    postClientNotesData(0);

  }, [chatClientList]);

  //Fetch Client Courses
  useEffect(() => {
    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }
    const payload = {
      userId: checkClientID,
    };

    const postClientNotesData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/new-uwc/community/course/active_courses`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const { courses } = await postResponse.data;

        setClientCourses(courses);
        //setUserData(postResponse?.data);

        //console.log("data", data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientNotesData();
    setLoading(false);
  }, [checkClientID]);

  //Fetch Client SessionNotes
  useEffect(() => {
    const fetchSessionNotes = async () => {
      try {
        const sessionData = await getSessionNotes(
          currentUser.UserId,
          checkClientID
        );

        setClientSessionNotes(sessionData?.notes);
        setLoading(false);
        // console.log("sessionData", sessionData);

        //setAppointmentsData(appointments);
      } catch (error) {
        console.error("Error fetching sessionNotes:", error);
      }
    };

    if (!checkClientID) {
      // If checkClientID is blank, return early
      return;
    }

    if (checkClientID !== undefined) {
      fetchSessionNotes();
    }
  }, [checkClientID]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewScreener = (title, id, type) => {
    //console.log("mid", id)
    onScreenerView(title, id, type);
  };

  //Notes
  const handleNotesOpen = () => {
    setOpenNotesModal(true);
  };

  const handleNotesClose = () => {
    setOpenNotesModal(false);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  //Send Note
  const handlePostNote = async () => {
    setIsNoteAdded(false);

    const payload = {
      jsonrpc: "2.0",
      params: {
        name: note,
        content: note,
        creator_id: currentUser?.UserId,
        assign_id: +clientDetails?.id,
        is_private: "True",
      },
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_notes/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse?.ResponseCode;

      if (message == 200) {
        setNote("");
        setOpenNotesModal(false);
        setSnackbarMessage("Note sent successfully!");
        setOpenSnackbar(true);
        setIsNoteAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Redirect to chat
  const handleChat = () => {
    navigate(`/expert/chat?clientID=${clientID}&clientName=${clientName}&type=redirect`);
  };

  ///Session Notes Region Start

  const handleSessionNotesClose = () => {
    setOpenSessionNotesModal(false);
  };

  const fetchSessionNotesById = async (id) => {
    try {
      const sessionDataID = await getSessionNotesById(currentUser?.UserId, id);

      setSessionNoteView(sessionDataID?.notes);

      if (sessionDataID?.notes !== undefined) {
        setOpenSessionNotesModal(true);
      } else {
        enqueueSnackbar("Oops! Looks like this Record doesn't exist!", {
          variant: "error",
        });
        // alert("Oops! Looks like this Record doesn't exist!");
      }

      //console.log("sessionDataID?.notes", sessionDataID)
    } catch (error) {
      console.error("Error fetching session notes by ID:", error);
    }
  };

  const sortedClientSessionNotes = clientSessionNotes?.sort(function (a, b) {
    var dateA = new Date(a.date);
    var dateB = new Date(b.date);

    return dateB - dateA;
  });

  ///Session Notes Region End


  return (
    <>
      <Grid container spacing={2} sx={{ marginTop: "20px" }}>
        <Grid item xs={8}>
          <div
            className="border p-3"
            style={{
              background: "#fff",
              borderRadius: "10px",

            }}
          >
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              className="ClientDetails--Tabs"
            >
              <Tab label="Appointments" />
              {/* <Tab label="Worksheets" /> */}
              {/* <Tab label="Notes" /> */}
              {/* <Tab label="Prescriptions" /> */}
              <Tab label="Screeners" />
              <Tab label="Courses" />
              {/* <Tab label="Session Notes" /> */}
            </Tabs>

            {/* Appointments Panel */}
            <CustomTabPanel value={selectedTab} index={0}>
              <div className="Tab--Panel Appointments--Panel">
                {/* Appointment */}
                {isLoading
                  ? Array.from(new Array(10)).map((_, index) => (
                    <div
                      style={{
                        width: "100%",
                        height: "70px",
                        backgroundColor: "rgb(0,0,0,0.07)",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      className="d-flex flex-column gap-2"
                    >
                      <Skeleton
                        variant="rounded"
                        width={75}
                        height={25}
                        sx={{ borderRadius: "20px" }}
                      />
                      <div className="d-flex flex-row gap-3">
                        <Skeleton
                          variant="rounded"
                          width="5vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          width="5vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          width="5vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>
                  ))
                  : clientAppointmentsList?.length > 0 ?
                    <>
                      <Table stickyHeader>
                        {/* Table Head */}
                        <TableHead>
                          <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Duration</TableCell>

                          </TableRow>
                        </TableHead>

                        {/* Table Body */}
                        <TableBody>
                          {clientAppointmentsList?.map((item) => (
                            <AppointmentTabItem
                              ID={item.ID}
                              Status={item.Status}
                              AppDate={item.AppDate}
                              AppTime={item.AppTime}
                              duration={item.duration}
                            />
                          ))}
                        </TableBody>
                      </Table>
                      <div className="d-flex justify-content-end w-100">
                        <Pagination count={totalPage} page={page} onChange={handleChangePage} />
                      </div>
                    </> : <div className="NoDataAvailable">
                      {NoDataSvg}
                      <p>No Appointments Booked</p>
                    </div>
                }
              </div>
            </CustomTabPanel>
            {/* Screeners Panel */}
            <CustomTabPanel value={selectedTab} index={1}>
              <div className="Tab--Panel Screeners--Panel">
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div
                      style={{
                        width: "100%",
                        height: "70px",
                        backgroundColor: "rgb(0,0,0,0.07)",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      className="d-flex justify-content-between"
                    >
                      <div className="d-flex flex-column gap-2">
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="20vw"
                          sx={{ borderRadius: "10px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="15vw"
                          sx={{ borderRadius: "10px" }}
                        />
                      </div>

                      <div className="CTA--Container d-flex gap-2 align-items-center">
                        <Skeleton
                          variant="rounded"
                          height={38}
                          width={86}
                          sx={{ borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  ))
                ) : mergedClientScreeners.length > 0 ? (

                  <Table  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Submitted On</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody >
                      {mergedClientScreeners.map((item) => (
                        <ScreenerTabItem
                          key={item.id}
                          type={item.type}
                          id={item.id}
                          title={item.name}
                          submittedOn={dayjs(item.score_date).format("DD MMM YYYY")}
                          onViewClick={handleViewScreener}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div className="NoDataAvailable">
                    {NoDataSvg}
                    <p>No Screeners Taken</p>
                  </div>
                )}
              </div>
            </CustomTabPanel>

            {/* Courses Panel */}
            <CustomTabPanel value={selectedTab} index={2}>
              {loading ? (
                Array.from(new Array(10)).map((_, index) => (
                  <>
                    <div
                      className="d-flex flex-row gap-2 align-items-center"
                      style={{
                        // backgroundColor: "rgb(0,0,0,0.09)",
                        borderRadius: "10px",
                        padding: "5px",
                      }}
                    >
                      <Skeleton
                        width={122}
                        height={69}
                        sx={{ borderRadius: "10px" }}
                      />

                      <div className="d-flex flex-column gap-2">
                        <Skeleton
                          variant="rounded"
                          width="15vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          width="10vw"
                          height={20}
                          sx={{ borderRadius: "5px" }}
                        />
                      </div>
                    </div>
                    <hr />
                  </>
                ))
              ) : clientCourses?.length > 0 ? (
                <Table className="mt-4">
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {clientCourses?.map((item) => (
                      <CourseTabItem
                        key={item.id}
                        title={item.name}
                        status={item.status}
                        img={item.website_thumbnail_url}
                      />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="NoDataAvailable">
                  {NoDataSvg}
                  <p>No Courses Taken</p>
                </div>
              )}
            </CustomTabPanel>
            {/* <CustomTabPanel value={selectedTab} index={1}>
            <div className="Tab--Panel Worksheets--Panel">
              <div className="WorksheetItem">
                <h6>Sleep Activity</h6>
                <span>Assigned on : 10 may 2023</span>
                <span>Weekly</span>
              </div>
            </div>
          </CustomTabPanel> */}

            {/* Notes Panel */}
            {/* <CustomTabPanel value={selectedTab} index={1}>
              <div className="Tab--Panel Notes--Panel">
                {loading ? (
                  <div className="NotesItem my-2">
                    {Array.from(new Array(10)).map((_, index) => (
                      <div className="mb-3">
                        <Skeleton variant="rounded" width="50%" height={20} />
                        <p>
                          <Skeleton variant="rounded" width="30%" height={20} />
                        </p>
                      </div>
                    ))}
                  </div>
                ) : clientNotes.length > 0 ? (
                  <>

                    <Table stickyHeader>
                
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Content</TableCell>
                        </TableRow>
                      </TableHead>

                   
                      <TableBody>
                        {clientNotes?.map((note) => (
                          <NoteTabItem
                            id={note.id}
                            create_date={note.create_date}
                            name={note.name}
                            content={note.content}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </>

                ) : (
                  <p style={{ fontWeight: "500", textAlign: "center" }}>
                    There are no notes attached with {clientViewDetails?.name}.
                  </p>
                )}
           
              </div>
            </CustomTabPanel> */}

            {/* Prescriptions panel */}
            {/* <CustomTabPanel value={selectedTab} index={2}>
              <div className="Tab--Panel Prescriptions--Panel">
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex align-items-center gap-2 justify-content-between">
                        <Skeleton
                          variant="rounded"
                          width="30%"
                          height={18}
                          sx={{ borderRadius: "10px" }}
                        />

                        <Skeleton variant="circular" width={22} height={15} />
                      </div>

                      <Skeleton
                        variant="rounded"
                        width="20%"
                        height={18}
                        sx={{ borderRadius: "10px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        width="20%"
                        height={18}
                        sx={{ borderRadius: "10px" }}
                      />
                    </div>
                  ))
                ) : clientPrescriptions.length > 0 ? (
                  <>
                    <Table stickyHeader>
                     
                      <TableHead>
                        <TableRow>
                          <TableCell>Title</TableCell>
                          <TableCell>Note</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>File</TableCell>
                        </TableRow>
                      </TableHead>

                    
                      <TableBody>
                        {clientPrescriptions?.map((item) => (
                          <PrescriptionTabItem
                            Id={item.Id}
                            Title={item.Title}
                            Note={item.Note}
                            CreatedDateTime={item.CreatedDateTime}
                            DocsFile={item.DocsFile}
                          />
                        ))}
                      </TableBody>
                    </Table>

                  </>

                ) : (
                  <p style={{ fontWeight: "500", textAlign: "center" }}>
                    There are no prescription notes attached with{" "}
                    {clientViewDetails?.name}.
                  </p>
                )}
              </div>
            </CustomTabPanel> */}



            {/* Session Notes Panel */}
            {/* <CustomTabPanel value={selectedTab} index={5}>
              <div className="Tab--Panel SessionNotes--Panel">
                {loading ? (
                  Array.from(new Array(10)).map((_, index) => (
                    <div
                      style={{
                        width: "100%",
                        height: "70px",
                        backgroundColor: "rgb(0,0,0,0.07)",
                        padding: "10px",
                        borderRadius: "10px",
                      }}
                      className="d-flex justify-content-between"
                    >
                      <div className="d-flex flex-column gap-2">
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="20vw"
                          sx={{ borderRadius: "10px" }}
                        />
                        <Skeleton
                          variant="rounded"
                          height={18}
                          width="15vw"
                          sx={{ borderRadius: "10px" }}
                        />
                      </div>

                      <div className="CTA--Container d-flex gap-2 align-items-center">
                        <Skeleton
                          variant="rounded"
                          height={38}
                          width={86}
                          sx={{ borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  ))
                ) : sortedClientSessionNotes.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Session Name</TableCell>
                        <TableCell>Last Update Date</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {sortedClientSessionNotes.map((item) => (
                        <SessionNoteTabItem
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          date={item.date}
                          time={item.time}
                          onView={fetchSessionNotesById}
                        />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <p style={{ fontWeight: "500", textAlign: "center" }}>
                    {clientViewDetails?.name} has no session notes attached.
                  </p>
                )}
              </div>
            </CustomTabPanel> */}
          </div>
        </Grid>

        {isLoading ? (
          <Grid item xs={4}>
            <div
              className="ClientDetailsGrid--Right"
              style={{
                background: "#fff",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <Skeleton variant="circular" width={110} height={110} />
              <Skeleton variant="text" width="30%" height={20} />

              <div className="d-flex align-items-center gap-2 ClientActions--Container">
                <Skeleton
                  variant="rounded "
                  width={168}
                  height={38}
                  sx={{ borderRadius: "20px" }}
                />
                <Skeleton
                  variant="rounded "
                  width={168}
                  height={38}
                  sx={{ borderRadius: "20px" }}
                />
              </div>

              <div className="d-flex align-items-center gap-2 CTA--Container">
                <Skeleton
                  variant="rounded"
                  width={335}
                  height={50}
                  sx={{ borderRadius: "10px" }}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={4}>
            <div
              className="ClientDetailsGrid--Right border rounded-3 p-3"

            >
              <img
                src={clientDetails.image}
                width={110}
                height={110}
                style={{ backgroundColor: "rgb(0,0,0,0.07)" }}
              />
              <h6>{clientDetails.name}</h6>
              <span>{clientDetails.country}</span>

              {/* <div className="d-flex align-items-center gap-2 ClientActions--Container">
                <Button onClick={handleChat}>
                  <ChatOutlinedIcon />
                  Chat
                </Button>

                <Button onClick={handleNotesOpen}>
                  <TextSnippetOutlinedIcon />
                  Add Notes
                </Button>

               
              </div> */}
              <div className="d-flex align-items-center w-100 gap-2 mt-4">


                <Button
                  fullWidth
                  variant="text"
                  sx={{
                    borderRadius: '20px', // Rounded but not too much
                    bgcolor: '#f5f5f5',
                    color: '#414141',
                    fontSize: '16px', // 14px as in the default theme
                    textTransform: 'capitalize',
                    '&:hover': {
                      background: "#f5f5f5"
                    }
                  }} className=" rounded-3 d-flex align-items-center justify-content-center gap-3"

                  onClick={handleChat}>
                  <ChatBubbleOutlineOutlined sx={{ color: "black", fontSize: "20px" }} />    Chat
                </Button>
                {/* <Button
                  fullWidth
                  variant="text"
                  sx={{
                    borderRadius: '20px', // Rounded but not too much
                    bgcolor: 'var(--primary-base-color)',
                    color: '#FFFFFF',
                    fontSize: '16px', // 14px as in the default theme
                    textTransform: 'capitalize',
                    '&:hover': {
                      background: "var(--primary-base-color-darken)"
                    }
                  }} className=" rounded-3 d-flex align-items-center justify-content-center gap-3"

                  onClick={handleNotesOpen}>
                  <TextSnippetOutlined sx={{ color: "white", fontSize: "20px" }} />
                  Add Notes
                </Button> */}
              </div>

              <div className="d-none align-items-center gap-2 CTA--Container">
                {/* <Button
                sx={{ flex: "1", minWidth: "0" }}
                variant="contained"
                className="PrimaryCTA"
                disableElevation
              >
                Book Appointment
              </Button> */}

                <AddAppointmentModal clientDetails={clientViewDetails} />
              </div>

              <span
                className="d-none"
                style={{ color: "#6A6C6A", fontSize: "14px" }}
              >
                Last Active :{" "}
                <b style={{ color: "#000" }}>{clientDetails.last_active}</b>
              </span>
            </div>
          </Grid>
        )}
      </Grid>

      {/* Notes Dialog */}
      <Dialog
        className="Dialog Notes--Dialog"
        open={openNotesModal}
        onClose={handleNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isNoteAdded !== null && !isNoteAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientDetails.image}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientDetails.name}</h6>
              {/* <span>
                {formattedDate} | {appointmentTime} | {clientType}
              </span> */}
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Write Something"
            multiline
            value={note}
            rows={4}
            fullWidth
            onChange={(e) => handleNote(e)}
            sx={{ marginTop: "20px" }}
          />

          <Button
            className="PrimaryCTAExp"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostNote}
            disabled={note == "" ? true : false}
          >
            Add Note
          </Button>
        </DialogContent>
      </Dialog>

      {/* Session Notes Dialog */}
      <Dialog
        className="Dialog SessionNotesView--Dialog"
        open={openSessionNotesModal}
        onClose={handleSessionNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DialogTitle>{sessionNoteView?.name}</DialogTitle>
        <DialogContent className="Dialog--Content">
          <Box p={3}>
            {/* Client Details */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Client Details
            </Typography>
            <Grid container spacing={2} className="sessionNoteView--ClientDetails">
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight="500">
                  Client’s Contact No:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {sessionNoteView?.client_mobile_no || "N/A"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight="500">
                  Client’s DOB:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {sessionNoteView?.client_dob || "N/A"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle1" fontWeight="500">
                  Client’s Address:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {sessionNoteView?.client_address || "N/A"}
                </Typography>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            {/* Note Details */}
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Note Details
            </Typography>
            {sessionNoteView?.responses?.map((item, i) => (
              <Accordion
                key={item.id}
                className="sessionNoteView--Accordion mt-3"
                elevation={2}
                sx={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "10px",
                  mb: 2,
                  "&:before": { display: "none" }, // Hide default accordion divider line
                  boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": { boxShadow: "0 5px 12px rgba(0,0,0,0.15)" },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownIcon
                      sx={{
                        transform: "rotate(0deg)",
                        transition: "transform 0.3s ease",
                        "&.Mui-expanded": { transform: "rotate(180deg)" },
                      }}
                    />
                  }
                  aria-controls={`panel-${i}-content`}
                  id={`panel-${i}-header`}
                  sx={{
                    backgroundColor: "white", // Customize the background of the accordion header
                    color: "var(--primary-base-color)",
                    borderRadius: "10px 10px 0 0",
                    padding: "10px 20px",
                    "&:hover": {
                      backgroundColor: "var(--primary-accent-color)",
                    },
                  }}
                >
                  <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                    {item.question_text}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: "#fff",
                    padding: "16px 20px",
                    borderRadius: "0 0 10px 10px",
                  }}
                >
                  <Typography variant="body2">
                    {item.response_text && item.response_text[0]}

                    {item.sub_questions && (
                      <Box mt={2}>
                        <Typography variant="subtitle1" fontWeight="500" mb={1}>
                          Sub-questions:
                        </Typography>
                        <ul style={{ paddingLeft: "20px", margin: 0 }}>
                          {item?.sub_questions?.map((question) => (
                            <li key={question.id}>
                              <Typography variant="body2">
                                <strong>{question.question_text}:</strong>{" "}
                                {question.response_text[0]}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    )}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ClientDetailsView;
